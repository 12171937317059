/**
 * @file
 * These values represent configuration for enabling or disabling the mocking of services.
 * Keys are class names and values are a boolean representing whether the service should
 * be mocked.
 *
 * **Note:** This is flat so that it can be used before the environment service is ready.
 */

// The following are special constant values that can be used to represent special values
// other than `true` or `false` in the `mocked` object.
// - `true`: The service should be mocked.
// - `false`: The service should not be mocked.

/**
 * A special value denoting that a service should only be mocked in the local development
 * environment.
 */
export const local = Symbol('local');

// The following are the services that can be mocked. The keys are the class names of the
// services and the values are booleans representing whether the service should be mocked.
// In addition to `true` and `false`, certain other special values are allowed as listed above.
const mocked = {
  /** `src/services/isomorphic/AccountService`. */
  AccountService: false,
  /** `src/services/isomorphic/CartCalculationService`. */
  CartCalculationService: false,
  /** `src/services/isomorphic/CartService`. */
  CartService: false,
  /** `src/services/isomorphic/CategoryService`. */
  CategoryService: false,
  /** `src/services/isomorphic/deprecated/ContentService`. */
  ContentService: false,
  /** `src/services/serverless/integrations/CoremediaService`. */
  CoremediaService: false,
  /** `src/services/isomorphic/EmailService`. */
  EmailService: false,
  /** `src/services/isomorphic/integrations/ElasticPathService/Carts`. */
  EPCartService: false,
  /** `src/services/isomorphic/integrations/ElasticPathService/Hierarchies`. */
  EPHierarchyService: false,
  /** `src/services/isomorphic/integrations/ElasticPathService/Products`. */
  EPProductService: false,
  /** `src/services/isomorphic/FeatureService`. */
  FeatureService: false,
  /** `src/services/isomorphic/InventoryService`. */
  InventoryService: false,
  /** `src/services/serverless/ServerInventoryService`. */
  ServerInventoryService: false,
  /** `src/services/isomorphic/LoggerService`. */
  LoggerService: local, // The logger service will be mocked only in local development environments.
  /** `src/services/isomorphic/ProductService`. */
  ProductService: false,
  /** `src/services/isomorphic/PromotionsService`. */
  PromotionsService: false,
  /** `src/services/serverless/integrations/YotpoService`. */
  YotpoService: false,
  /** `src/services/serverless/integration/DYService`. */
  DYService: false,
  /** `src/services/serverless/integrations/SafetechService`. */
  SafetechService: false,
  /** `src/services/serverless/integrations/DOMSJerryService`. */
  DOMSJerryService: false,
  /** `src/services/isomorphic/integrations/GTMService`. */
  GTMService: false,
  /** `src/services/isomorphic/GiftCertificateService`. */
  GiftCertificateService: false,
  /** `src/services/serverless/integrations/AvaTaxService`. */
  AvaTaxService: false,
  /** `src/services/isomorphic/TaxService`. */
  TaxService: false,
  /** `src/services/isomorphic/UserService`. */
  UserService: false,
  /** `src/services/isomorphic/integrations/CoveoSearchService`. */
  CoveoSearchService: false,
  /** `src/services/isomorphic/integrations/CoveoAnalyticsService`. */
  CoveoAnalyticsService: false,
  /** `src/services/isomorphic/ReturnsLabelService`. */
  ReturnsLabelService: false,
  /** `src/services/isomorphic/PersonalizationService`. */
  PersonalizationService: false,
  /** `src/services/serverless/integrations/PitneyBowesReturnsService`. */
  PitneyBowesReturnsService: false,
  /** `src/services/isomorphic/RecommendationService`. */
  RecommendationService: false,
  /** `src/services/isomorphic/ClientReviewsService`. */
  ClientReviewsService: false,
  /** `src/services/isomorphic/integrations/TalonOneService`. */
  TalonOneService: false,
  /** `src/services/isomorphic/SessionService`. */
  SessionService: false,
  /** `src/services/isomorphic/ShippingMethodService`. */
  ShippingMethodService: false,
  /** `src/services/isomorphic/LocaleSelectionService`. */
  LocaleSelectionService: false,
  /** `src/services/isomorphic/PlaceOrderService`. */
  PlaceOrderService: false,
  /** `src/services/serverless/ServerPlaceOrderService`. */
  ServerPlaceOrderService: false,
  /** `src/services/isomorphic/OrderLookupService`. */
  OrderLookupService: false,
  /** `src/services/serverless/integrations/ForterService`. */
  ForterService: false,
  /** `src/services/isomorphic/FraudService`. */
  FraudService: false,
  /** `src/services/isomorphic/SizeChartService`. */
  SizeChartService: false,
  /** `src/services/isomorphic/BlogService`. */
  BlogService: false,
  /** `src/services/isomorphic/integrations/BuilderService`. */
  BuilderService: false,
  /** `src/services/serverless/integrations/AWS/AWSAccountService`. */
  AWSAccountService: false,
  /** `src/services/serverless/integrations/AWS/AWSReturnsPlacementService`. */
  AWSReturnsPlacementService: false,
  /** `src/services/integration/AWSBlogService`. */
  AWSBlogService: false,
  /** `src/services/serverless/integrations/AWS/AWSOrderIDService`. */
  AWSOrderIDService: false,
  /** `src/services/serverless/integrations/AWS/AWSOrderEventService`. */
  AWSOrderEventService: false,
  /** `src/services/serverless/integrations/AWS/AWSOrderLookupService`. */
  AWSOrderLookupService: false,
  /** `src/services/serverless/integrations/AWS/AWSProductCatalogService`. */
  AWSProductCatalogService: false,
  /** `src/services/serverless/integrations/AWS/AWSShippingMethodService`. */
  AWSShippingMethodService: false,
  /** `src/services/serverless/integration/EasyPostService`. */
  EasyPostService: false,
  /** `src/services/serverless/integration/OTelService'. */
  OTelService: false,
  /** `src/services/serverless/integrations/UPSReturnsLabelService`. */
  UPSReturnsLabelService: false
} as const;

export default mocked;
