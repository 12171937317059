import { DEFAULT_REGION } from '@/configs/env/public';
import { Primitive } from '@/type-utils';
import type { Path as PathType, PathValue } from '@/type-utils/Path';

import StaleWhileRevalidate from '@/utils/StaleWhileRevalidate';
import { InvalidArgumentError } from '@/utils/errors';
import { exhaustiveGuard } from '@/utils/function-utils';
import { ReactElement } from 'react';
import { Language } from '@/constructs/Language';
import { Country } from '@/constructs/Country';
import I18NService, { StringContainer } from './I18NService';
import { countries_US } from "@/lang/__generated__/ahnu/countries_US";
import { countries_UK } from "@/lang/__generated__/ahnu/countries_UK";
import { countries_CA } from "@/lang/__generated__/ahnu/countries_CA";
import { countries_GB } from "@/lang/__generated__/ahnu/countries_GB";
import { countries_DE } from "@/lang/__generated__/ahnu/countries_DE";
import { countries_FR } from "@/lang/__generated__/ahnu/countries_FR";
import { countries_IT } from "@/lang/__generated__/ahnu/countries_IT";
import { countries_JP } from "@/lang/__generated__/ahnu/countries_JP";
import { countries_NL } from "@/lang/__generated__/ahnu/countries_NL";
import { language_en } from "@/lang/__generated__/ahnu/language_en";
import { language_es } from "@/lang/__generated__/ahnu/language_es";
import { language_fr } from "@/lang/__generated__/ahnu/language_fr";
import { language_de } from "@/lang/__generated__/ahnu/language_de";
import { language_it } from "@/lang/__generated__/ahnu/language_it";
import { language_jp } from "@/lang/__generated__/ahnu/language_jp";
import { language_nl } from "@/lang/__generated__/ahnu/language_nl";

const regionConfig = {
  US: {
    supportedCurrencies: ['USD'],
    defaultCurrency: 'USD',
    locales: ['en', 'en-US'],
    defaultLocale: 'en'
  }
} as const;

export const i18nConfig = {
  defaultRegion: DEFAULT_REGION,
  regionConfig
} as const;

export { Language } from '@/constructs/Language';
export { Country } from '@/constructs/Country';

export * from '@/constructs/LocaleSchema';
export * from '@/lang/types/Resource';

export default I18NService;

/**
 * Retrieves a localized resource string for the current locale given a path to the
 * string. Resource strings are defined in the `lang` directory. Subdirectories represent
 * the brand and then the locale. For example, the string `lang/sanuk/en-US/general.ts`.
 * @param msgID - The resource string identifier for this resource. Will be a dot
 * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
 * the file in the `lang` directory, `key` is the key in the exported object, and
 * `nestedKey` could be a nested key in the object, and so on.
 * @returns The localized resource string.
 */
export const msg = <
  Path extends PathType<T>,
  Value extends PathValue<T, Path>,
  T = StringContainer['default']['default']
>(
  msgID: Path
): Value => {
  return I18NService.msg<Path, Value, T>(msgID);
};

/**
 * Retrieves a localized resource string for the current locale given a path to the
 * string. Resource strings are defined in the `lang` directory. Subdirectories represent
 * the brand and then the locale. For example, the string `lang/sanuk/en-US/general.ts`.
 * @param msgID - The resource string identifier for this resource. Will be a dot
 * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
 * the file in the `lang` directory, `key` is the key in the exported object, and
 * `nestedKey` could be a nested key in the object, and so on.
 * @param values - A record of values to be interpolated into the string. The possible
 * values are declared in the resource string itself.
 * @returns The localized resource string formatted with the passed in values in
 * accordance with the Intl MessageFormat spec.
 *
 * @see https://formatjs.io/docs/core-concepts/icu-syntax
 * @see https://formatjs.io/docs/intl-messageformat/#common-usage-example
 */
export const msgf = <
  Path extends PathType<T>,
  Value extends PathValue<T, Path>,
  T = StringContainer['default']['default']
>(
  msgID: Path,
  values: Record<string, Primitive | ReactElement | JSX.Element>
): Value => {
  return I18NService.msgf<Path, Value, T>(msgID, values);
};

/**
 * Dynamically retrieves a localized resource string for the current locale given a path
 * to the string. Resource strings are defined in the `lang` directory. Subdirectories
 * represent the brand and then the locale. For example, the string
 * `lang/sanuk/en-US/general.ts`. The difference between this method and `msg` is that
 * this method will return a {@link StaleWhileRevalidate} object that will automatically
 * update the string if the resource ID changes.
 *
 * **Note:** This method is _not_ type safe. It is recommended to use `msg` or `msgf`
 * instead for type safety. Furthermore, this method should only be used when the
 * resource ID is not known at compile time. The method necessarily requires that all
 * resource strings be loaded at runtime, which can have a sizeable impact on the
 * perceived performance of the application.
 *
 * @param msgID - The resource string identifier for this resource. Will be a dot
 * notation string in the form of `file.key.nestedKey.etc` where `file` is the name of
 * the file in the `lang` directory, `key` is the key in the exported object, and
 * `nestedKey` could be a nested key in the object, and so on.
 * @returns The localized resource string.
 * @deprecated Use `msg` or `msgf` instead for type safety and better performance.
 */
export const msgDynamic = (msgID: string): StaleWhileRevalidate<string> => {
  return I18NService.msgDynamic(msgID);
};

/**
 * Takes a date and formats it based on the current locale and options provided.
 * @param date - The date to format.
 * @param options - Options to pass to the formatter.
 * @returns The date formatted.
 */
export const formatDate = (
  date: Date,
  options: Intl.DateTimeFormatOptions
): string => {
  return I18NService.formatDate(date, options);
};

/**
 * Retrieves the currency symbol based in current locale.
 * @returns - The current currency symbol.
 */
export const currentCurrencySymbol = (): string => {
  return I18NService.currentCurrencySymbol();
};

/**
 * Retrieves the display name for the specified country.
 *
 * **NOTE:** Names must be configured via the `countries` lang file.
 *
 * @param country - The country to get the name for.
 * @returns The specified country's display name.
 *
 * @throws An {@link InvalidArgumentError} if the specified country does not
 * have a configured display name.
 */
export const getCountryName = (country: Country): string => {
  switch (country) {
    case Country.US:
      return msg(countries_US);
    case Country.UK:
      return msg(countries_UK);
    case Country.CA:
      return msg(countries_CA);
    case Country.GB:
      return msg(countries_GB);
    case Country.DE:
      return msg(countries_DE);
    case Country.FR:
      return msg(countries_FR);
    case Country.IT:
      return msg(countries_IT);
    case Country.JP:
      return msg(countries_JP);
    case Country.NL:
      return msg(countries_NL);
  }

  return exhaustiveGuard(
    country,
    `Cannot get the display name for country "${country}": ` +
      'The country does not have a configured display name. ' +
      'Did you update the `countries` lang file ' +
      'and the mapping in `getCountryName`?'
  );
};

/**
 * Retrieves the display name for the specified language.
 *
 * **NOTE:** Names must be configured via the `language` lang file.
 *
 * @param language - The language to get the name for.
 * @returns The specified language's display name.
 *
 * @throws An {@link InvalidArgumentError} if the specified language does not
 * have a configured display name.
 */
export const getLanguageName = (language: Language): string => {
  switch (language) {
    case Language.EN:
      return msg(language_en);
    case Language.ES:
      return msg(language_es);
    case Language.FR:
      return msg(language_fr);
    case Language.DE:
      return msg(language_de);
    case Language.IT:
      return msg(language_it);
    case Language.JP:
      return msg(language_jp);
    case Language.NL:
      return msg(language_nl);
  }

  return exhaustiveGuard(
    language,
    `Cannot get the display name for language "${language}": ` +
      'The language does not have a configured display name. ' +
      'Did you update the `language` lang file ' +
      'and the mapping in `getLanguageName`?'
  );
};
