import { Currency, MoneyModel } from '@/services/models/Money';
import { PageType, PageModel, type IPage } from '@/services/models/Page';
import { ProductModel } from '@/services/models/Product';
import { Nullable } from '@/type-utils';
import { InvalidArgumentError } from '@/utils/errors';
import axios, { AxiosInstance } from 'axios';
import Service from '../../Service';
import {
  DYPageTypeMap,
  DYService,
  IDYChoiceConfiguration,
  IDYEventConfiguration,
  IDYEventProduct,
  IDYPageViewConfiguration,
  IDYSelector
} from '../../serverless/integrations/DYService';

import { IDYChoice } from '../../serverless/integrations/DYService/schema/response/IDYChoice';
import siteCached from '../../utils/siteCached';
import CartService from '../CartService';
import ConfigurationService, { Config } from '../ConfigurationService';
import { EnvironmentService } from '../EnvironmentService';
import i18nService from '../I18NService';
import LoggerService from '../LoggerService';
import {
  EventType,
  IInteractionPageDetails,
  InteractionDetails
} from '../UserInteractionService/IInteractionDetails';
import { PersonalizationData } from './PersonalizationData';
import { PersonalizationDataType } from './PersonalizationDataType';
import PersonalizationServiceMock from './PersonalizationServiceMock';
import {
  DecisionIDOf,
  DecisionsFor,
  ExperienceID,
  IDecision,
  decisionSchemas
} from './campaigns';
import { PageService } from '../PageService';

/**
 * The choice data coming back from DY.
 */
export interface IChoiceData {
  /**
   * The data object from the api request.
   */
  data: {
    /**
     * The choices that come back from DY, in DY shape.
     */
    choices: Array<IDYChoice>;
  };
}

/**
 * Handles the swapping of different experiences based on a personalization
 * integration service (Dynamic Yield Service).
 */
export class PersonalizationService extends Service {
  /**
   * The Dynamic Yield config.
   * @returns A `Config<'dynamicYield'>`.
   */
  @siteCached
  private get dyConfig(): Config<'dynamicYield'> {
    return ConfigurationService.getConfig('dynamicYield');
  }

  private client: AxiosInstance;

  /** Constructs a new `PersonalizationService`. */
  public constructor() {
    super();

    // We're working with `/api/reviews` and `/api/questions`
    this.client = axios.create({
      baseURL: '/api/personalization'
    });
  }

  /**
   * Converts our personalization data into the format needed in the `DYService`.
   * @param personalizationData - Contains a page and the ids for the experiences we want to retrieve.
   * @returns The body object used to request the choice from the DYService.
   */
  private getDYConfigurationObject(
    personalizationData: PersonalizationData
  ): IDYChoiceConfiguration | IDYPageViewConfiguration {
    const { type } = personalizationData;
    let { page } = personalizationData;
    const { language, country } = i18nService.currentLocale;
    if (!page) {
      page = {
        pageType: PageType.Unknown,
        url: EnvironmentService.url.origin
      };
    }
    const pageModel = PageModel.from(page);
    const location = pageModel.canonicalURL
      ? pageModel.canonicalURL.toString()
      : EnvironmentService.url.origin;

    let selector: Nullable<IDYSelector> = null;
    if (type === PersonalizationDataType.Choice) {
      const { ids } = personalizationData;
      selector = ids
        ? {
            names: ids
          }
        : undefined;

      const { url } = EnvironmentService;
      if (url.searchParams.has(DYService.previewParam)) {
        const previewId = url.searchParams.get(
          DYService.previewParam
        ) as string;
        if (selector) {
          selector = {
            ...selector,
            preview: {
              ids: [previewId]
            }
          };
        }
      }
    }

    let data: Array<string> = [];
    if (type === PersonalizationDataType.Category) {
      const { categories } = personalizationData;
      data = categories;
    }

    if (type === PersonalizationDataType.Product) {
      const { products } = personalizationData;
      data = products;
    }

    let dyConfiguration: IDYPageViewConfiguration = {
      context: {
        page: {
          type: DYPageTypeMap[pageModel.pageType],
          location,
          locale: `${language}_${country}`,
          data
        }
      }
    };

    if (selector) {
      dyConfiguration = {
        ...dyConfiguration,
        selector
      } as IDYChoiceConfiguration;
    }

    /**
     * This section is for adding analytics data off the window
     * object or other configuration that can only be assembled
     * on the client-side.
     */
    if ((typeof window !== "undefined")) {
      const device = {
        userAgent: window.navigator.userAgent
      };

      dyConfiguration.context = { ...dyConfiguration.context, ...device };
    }

    return dyConfiguration;
  }

  /**
   * Gets the choices from the `DYService`, if on the Server it directly
   * asks the service. If on the client it makes the request to the api
   * route.
   * @param experienceNames - The names of the experiences in an array.
   * @param page - The current page being assembled or that the user is on.
   * @returns The choices from the `DYService`.
   */
  private async getChoices<const EIDs extends Array<ExperienceID>>(
    experienceNames: EIDs,
    page?: Nullable<IPage>
  ): Promise<Array<IDYChoice>> {
    const dyConfiguration = this.getDYConfigurationObject({
      type: PersonalizationDataType.Choice,
      ids: experienceNames,
      page
    }) as IDYChoiceConfiguration;

    if ((typeof window === "undefined")) {
      const { choices } = await DYService.chooseVariations(dyConfiguration);

      return choices;
    }

    const { data } = (await this.client.post(
      `/choices`,
      dyConfiguration
    )) as IChoiceData;

    return data.choices ?? [];
  }

  /**
   * Gets a single experience .
   * @param experienceName - The experience name to be returned.
   * @param page - The current page being assembled or that the user is on.
   * @returns The decisions, a list of experience names with their chosen decision id.
   * @throws If the experience doesn't exist.
   */
  public async getExperienceForCurrentUser<const T extends ExperienceID>(
    experienceName: T,
    page?: Nullable<IPage>
  ): Promise<IDecision<T, DecisionIDOf<T>>> {
    const result = await this.getExperiencesForCurrentUser(
      [experienceName],
      page
    );

    if (!result[0]) {
      throw new InvalidArgumentError(
        `The experience name: ${experienceName}, is not a valid experience name.`
      );
    }

    return result[0] as IDecision<T, DecisionIDOf<T>>;
  }

  /**
   * Gets all the decisions for all requested experience names.
   * @param experienceNames - The experience names to be requested.
   * @param page - The current page being assembled or that the user is on.
   * @returns The choices in relation to each decision.
   *
   * @todo This method enables a potential optimization because DY allows us to
   * request variations for multiple selectors at the same time. As a result,
   * it would be more efficient to first group all the selectors on the page,
   * and then make one request for all of them.
   */
  public async getExperiencesForCurrentUser<
    const EIDs extends Array<ExperienceID>
  >(
    experienceNames: EIDs,
    page?: Nullable<IPage>
  ): Promise<DecisionsFor<EIDs | []>> {
    const choices = await this.getChoices(experienceNames, page);

    // Since this method deals with multiple decisions if none are found we should
    // return an empty object and log the missing experience. We don't want a misconfig
    // in the DY UI to take down the site. All methods that deal with this method should
    // handle for the empty object or the possibility that any given experience might
    // not have a decision associated with it.
    let decisions: DecisionsFor<EIDs> | [] = [];

    if (choices.length > 0) {
      const choiceNames = new Set(choices?.map((choice) => choice.name));
      const hasAllCampaigns = experienceNames.every((experienceName) =>
        choiceNames.has(experienceName)
      );

      if (!hasAllCampaigns) {
        LoggerService.warn(
          'Dynamic yield did not find a match for all selected experiences.'
        );
      }

      decisions = choices?.map((choice) => ({
        experienceID: choice.name,
        ...choice.variations[0].payload.data
      })) as DecisionsFor<EIDs>;

      const areDecisionPropsValid = decisions.every(({ decisionID, props }) => {
        return decisionSchemas[
          decisionID as DecisionIDOf<ExperienceID>
        ].safeParse(props ?? {}).success;
      });

      if (!areDecisionPropsValid) {
        LoggerService.warn('Some decisions received unexpected props.');
      }
    }

    return decisions;
  }

  /**
   * Sets the page context for personalization. This sends data to the {@link DYService}. Which uses that
   * data to set a context for the given user. This context is essentially a page view event.
   * @param interactionDetails - The page interaction event.
   * @see https://support.dynamicyield.com/hc/en-us/articles/360022955254#h_01F46DQ0R7HWBXVYHWAG642CYY
   */
  public async sendPageContext(
    interactionDetails: IInteractionPageDetails
  ): Promise<void> {
    const isDYEnabled = this.dyConfig.getSetting('isDYEnabled').value;

    if (isDYEnabled) {
      const { page, products, category } = interactionDetails;

      const categories = category?.breadcrumbs.map(
        (crumb) => crumb.displayValue
      );

      // Get upcs off the product, if the product has a upc, use that, if not use
      // the fallback upc from the product model.
      const upcs = products?.map((product) => {
        const productModel = ProductModel.from(product);
        const upc = product.upc ? product.upc : productModel.fallbackUPC;
        return upc as string;
      });

      let dyConfiguration: IDYPageViewConfiguration;
      if (categories) {
        dyConfiguration = this.getDYConfigurationObject({
          type: PersonalizationDataType.Category,
          categories: categories ?? [],
          page
        }) as IDYPageViewConfiguration;
      } else {
        dyConfiguration = this.getDYConfigurationObject({
          type: PersonalizationDataType.Product,
          products: upcs ?? [],
          page
        }) as IDYPageViewConfiguration;
      }

      if ((typeof window === "undefined")) {
        await DYService.sendReport('pageview', dyConfiguration);
      } else {
        await this.client.post(`/pageView`, dyConfiguration);
      }
    }
  }

  /**
   * Sends an event using the interaction details to construct an dy event.
   * This does not return anything, but could throw an error, which should be caught in
   * most cases.
   * @param interactionDetails - The interaction details used to construct the dy event.
   * Depending on the {@link EventType} different data will be sent.
   */
  public async sendEvent(
    interactionDetails: InteractionDetails
  ): Promise<void> {
    const isDYEnabled = this.dyConfig.getSetting('isDYEnabled').value;

    if (isDYEnabled) {
      const dyConfiguration: IDYEventConfiguration = {
        context: {},
        events: []
      };

      if (interactionDetails.action === EventType.ProductView) {
        dyConfiguration.events.push({
          name: 'PDP_VIEW'
        });
      }

      // Adding product add event data.
      if (interactionDetails.action === EventType.ProductAdd) {
        /** In a ProductAdd event, cart data should be assembled from the users current cart. */
        const cart = await CartService.getCartFromSession();
        // We don't need to call revalidate here because we
        // only care about the cart items, not the totals.

        /**
         * If there is a cart, it will be used to get the skus, quantities, and total prices
         * of all items in the cart. This information will be a part of the event data.
         */
        let cartItems: Array<IDYEventProduct> = [];
        if (cart) {
          cartItems = cart.items.map((item): IDYEventProduct => {
            return {
              productId: item.upc!,
              quantity: item.quantity,
              // eslint-disable-next-line local-rules/warn-against-moneymodel-asunsafenumber -- DY expects a number here.
              itemPrice: MoneyModel.asUnsafeNumber(item.netTotal)
            };
          });
        }
        const { product } = interactionDetails;

        dyConfiguration.events.push({
          name: 'Add to Cart',
          properties: {
            dyType: 'add-to-cart-v1',
            value: product.price?.retailPrice
              ? // eslint-disable-next-line local-rules/warn-against-moneymodel-asunsafenumber -- DY expects a number here.
                MoneyModel.asUnsafeNumber(product.price.retailPrice)
              : 0,
            currency: product.price?.retailPrice.currency ?? Currency.USD,
            // Non-null asserted because the product should always be complete on cart.
            productId: product.upc!,
            quantity: 1,
            cart: cartItems
          }
        });
      }

      // Purchase order data.
      if (interactionDetails.action === EventType.OrderSuccess) {
        const { orderData, cartData } = interactionDetails;

        /**
         * In a purchase event the current users cart will already be empty by the time this
         * event is fired. Instead the cart data will be pulled from whatever cart was used to
         * populate the order data for the place order call.
         */
        const cartItems = cartData.items.map((item): IDYEventProduct => {
          return {
            // Non-null asserted because the product should always be complete on place order.
            productId: item.upc!,
            quantity: item.quantity,
            itemPrice: item.netTotal
              ? // eslint-disable-next-line local-rules/warn-against-moneymodel-asunsafenumber -- DY expects a number here.
                MoneyModel.asUnsafeNumber(item.netTotal)
              : 0
          };
        });

        dyConfiguration.events.push({
          name: 'Purchase',
          properties: {
            dyType: 'purchase-v1',
            // eslint-disable-next-line local-rules/warn-against-moneymodel-asunsafenumber -- DY expects a number here.
            value: MoneyModel.asUnsafeNumber(orderData.totals.total),
            currency: orderData.totals.total.currency,
            uniqueTransactionId: (orderData.orderID?.value as string) ?? '',
            cart: cartItems
          }
        });
      }

      if ((typeof window !== "undefined")) {
        const device = {
          userAgent: window.navigator.userAgent
        };

        dyConfiguration.context = { ...dyConfiguration.context, ...device };
      }
      if ((typeof window === "undefined")) {
        await DYService.sendReport('event', dyConfiguration);
      } else {
        /**
         * The `dyID` is required ahead of time for analytics events, but for choices one will be
         * assigned to the user if they do not have one.
         */
        await this.client.post(`/event`, dyConfiguration);
      }
    }
  }
}

export default PersonalizationService.withMock(
  new PersonalizationServiceMock(PersonalizationService)
) as unknown as PersonalizationService;
