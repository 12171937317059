/**
 * This file defines the {@link siteCached siteCachede} utility decorator for caching the first
 * result of methods and getters. At the moment, it only supports one type
 * of caching behavior. However, additional caching and invalidation strategies
 * may be added using the {@link setupCache} API, with heuristics to determine
 * when to create it going within the `siteCached` function.
 *
 * `@siteCached` was designed primarily for use in our services since
 * a cache is desireable for many hot path operations. However, the singleton
 * nature of our services prevents them from being able to naturally support
 * operations which may differ due to brand or country variations, such as
 * retrieving configuration values. As a result, `@siteCached` uses the
 * {@link I18NService} and {@link EnvironmentService} to cache with respect
 * to those variations, and to prevent false cache hits.
 *
 * @example
 * class MyService extends Service {
 *    // `config` is will be initialized lazily.
 *    ;@siteCached
 *    get config(): Config<'my_service'> {
 *      return ConfigurationService.getConfig('my_service');
 *    }
 *  }
 */

import { Site } from '@/constructs/Site';
import MemoryCache from '@/utils/MemoryCache';
import { EnvironmentService } from '../../isomorphic/EnvironmentService';
import I18NService, { Language, Country } from '../../isomorphic/I18NService';

/**
 * A unique identifier for a site, consisting of its brand, country, and language.
 */
export type SiteKey = `${Site}-${Country}-${Language}`;

/**
 * Represents a {@link MemoryCache} whose keys are {@link SiteKey SiteKeys}.
 * This is used to cache a property's value per
 * brand and country, as those may be dynamic values.
 */
type CacheBySite = MemoryCache<unknown, SiteKey>;

/**
 * A type alias for a {@link WeakMap} whose naming and usage
 * is consistent with other types in this file.
 *
 * More information regarding the choice of a `WeakMap`
 * can be found in the comments for {@link CacheBySiteByObject}.
 */
type CacheByObject<T extends object, V = unknown> = WeakMap<T, V>;

/**
 * Represents a mapping from object instances to their corresponding
 * site cache for a decorated property. This is a necessary
 * structure because the decorated version of a property
 * is defined only once (when the class is first defined).
 * As a result, the decorated property is shared between
 * instances, and in order to associate the correct data with
 * some object, we need to map from the instance to its cache.
 *
 * Under the hood, a {@link WeakMap} is used to similarly to how it's used to
 * emulate private members by tools like Babel. That is, the `WeakMap`
 * enables us to associate additional private data to an object,
 * without creating strong references that prevent garbage collection.
 * In other words, the cache's lifetime is tied to the lifetime of
 * the object it is "extending", and it will never be invalidated
 * as long as we have a direct reference to the object.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap#emulating_private_members "Emulating Private Members" on MDN}
 */
type CacheBySiteByObject<T extends object> = CacheByObject<T, CacheBySite>;

/**
 * A function type that returns the cache-capable equivalent of a given method
 * or getter. The passed in `cache` may define its own invalidation policies.
 *
 * This is mainly used to support different strategies for reading from
 * and writing to the given `cache`. However, generally, functions of this
 * type should check whether `this` exists in the `cache`, and return the
 * cached value. Otherwise, `methodOrGetter` should be invoked and the
 * result should be returned and written to `cache`.
 */
type ReplaceWithCache<T extends object, Cache extends CacheByObject<T>> = (
  methodOrGetter: (this: T, ...args: Array<unknown>) => unknown,
  cache: Cache
) => typeof methodOrGetter;

/**
 * A decorator that caches the result of a method or getter after the first invocation,
 * **per site** (brand and country). This can be used to lazily initialize
 * a property value and then reuse the result (see the example below).
 *
 * **Note**: applying this decorator to a method will cache its first returned value,
 * regardless of the arguments that are passed in subsequent invocations.
 *
 * @param target - The prototype which defines a method or accessor.
 * @param propertyKey - The name of the property to decorate.
 * @param descriptor - The corresponding property descriptor.
 * @throws Occurs if decorated on something other than a method or getter.
 *
 * @example
 *  class MyService extends Service {
 *    // `config` is will be initialized lazily.
 *    ;@siteCached
 *    get config(): Config<'my_service'> {
 *      return ConfigurationService.getConfig('my_service');
 *    }
 *  }
 *
 */
export function siteCached<T extends object>(
  target: T,
  propertyKey: string, // we can't use `keyof T` because we want to allow decorating private properties.
  descriptor: PropertyDescriptor
): void {
  /**
   * Since decorators are applied to property descriptors which live on the class
   * prototype, they have no explicit knowledge of the object instance they are modifying.
   * As a result, this decorator creates a `WeakMap` where the keys are instances of the class,
   * and the values are the cached data. A `WeakMap` ensures that the keys and values
   * may be garbage collected if the stored data is no longer used.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap WeakMap on MDN}.
   */
  const cacheByObject = new WeakMap();

  /**
   * Note: it is not possible to optimize the cache on the client
   * to be brand and country agnostic. This is because the client
   * performs SPA-style navigations when switching locales, meaning
   * all cached values remain in memory and may become stale.
   */
  setupCache(descriptor, cacheByObject, siteCacheReplacer);
}

/**
 * An abstract utility function which sets up the cache for the
 * given `descriptor`. The `cache` and `replacer` determine
 * how the behavior of the descriptor is transformed.
 *
 * @param descriptor - A property descriptor.
 * @param cache - A {@link CacheByObject} object which holds the cached data.
 * @param replacer - A {@link ReplaceWithCache} function that transforms the `descriptor`.
 * @throws Occurs if the descriptor is not a getter or method.
 */
function setupCache<T extends object, Cache extends CacheByObject<T>>(
  descriptor: PropertyDescriptor,
  cache: Cache,
  replacer: ReplaceWithCache<T, Cache>
): void {
  if (isFunction(descriptor.value)) {
    // The descriptor is for a class method.
    descriptor.value = replacer(descriptor.value, cache);
  } else if (descriptor.get !== undefined) {
    // The descriptor is for a class getter property.
    // eslint-disable-next-line @typescript-eslint/unbound-method -- The getter is bound within the `replacer`.
    descriptor.get = replacer(descriptor.get, cache);
  } else {
    throw new Error('@siteCached must only decorate class methods or getters.');
  }
}

/**
 * A type guard for arbitrary functions.
 * @param value - Any value whose type will be narrowed.
 * @returns A boolean indicating whether the given value is a function.
 */
function isFunction(
  value: unknown
): value is (...args: Array<unknown>) => unknown {
  return typeof value === 'function';
}

/**
 * A {@link ReplaceWithCache} which returns a function that caches its first result
 * **per site** (as determined by the {@link EnvironmentService} and the {@link I18NService}).
 * The return value should replace the method or getter being decorated.
 *
 * @param methodOrGetter - The method or getter to replace.
 * @param cacheBySiteByObject - The CacheBySiteByObject object.
 * @returns A function that caches its first result per site.
 */
function siteCacheReplacer<
  T extends object,
  F extends (this: T, ...args: Array<unknown>) => unknown
>(methodOrGetter: F, cacheBySiteByObject: CacheBySiteByObject<T>): F {
  // In order for `this` to be bound correctly, the function below cannot be an arrow function.
  return function _(this: T, ...args: Parameters<F>): ReturnType<F> {
    const { country, language } = I18NService.currentLocale;
      const site = (process.env.NEXT_PUBLIC_SITE_BRAND.toUpperCase());
    const siteCacheKey: SiteKey = `${site}-${country}-${language}`;

    if (cacheBySiteByObject.has(this)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We check that it is present in the WeakMap using the condition above.
      const cacheBySite = cacheBySiteByObject.get(this)!;
      if (cacheBySite.has(siteCacheKey)) {
        return cacheBySite.get(siteCacheKey) as ReturnType<F>;
      }

      /**
       * `call(this, ...)` is necessary for preserving the behavior of the original
       *  method or getter in the event that it uses `this` in its implementation.
       */
      const result = methodOrGetter.call(this, ...args) as ReturnType<F>;
      cacheBySite.add(siteCacheKey, result);
      return result;
    }

    const cacheBySite: CacheBySite = new MemoryCache();
    cacheBySiteByObject.set(this, cacheBySite);

    /** See earlier explanation for use of `call(this, ...)`. */
    const result = methodOrGetter.call(this, ...args) as ReturnType<F>;
    cacheBySite.add(siteCacheKey, result);
    return result;
  } as F;
}
